var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: "finish-step",
      staticClass: "flex items-stretch transition-opacity min-h-xl",
      class: {
        "opacity-0": !_vm.visible
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-col items-start justify-center order-1 p-6 md:order-0 md:p-8 lg:p-12 md:w-c6"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "mt-0 mb-auto font-light text-14 opacity-85 text-theme-fg"
            },
            [
              _vm._v("\n      Completed "),
              _c("svg-icon", {
                staticClass:
                  "inline-block ml-1 -mt-px align-middle fill-current text-theme-highlight",
                attrs: { icon: "circle-tick", size: "4" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.askForDemographics
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-4 font-light max-w-88 text-16 leading-medium"
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "mb-8 font-light leading-tight text-28" },
                      [
                        _vm._v(
                          "Before you go, could you tell us a bit more about yourself?"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        staticClass: "max-w-88",
                        attrs: { action: "/" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submitDemographics($event)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mr-4 font-normal whitespace-no-wrap text-16"
                            },
                            [_vm._v("\n              Postcode:\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "relative flex-1 ml-auto max-w-50 md:max-w-64"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.postcode,
                                    expression: "postcode"
                                  }
                                ],
                                staticClass:
                                  "flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16",
                                attrs: { placeholder: "e.g. N1", type: "text" },
                                domProps: { value: _vm.postcode },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.postcode = $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex items-center mt-2" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mr-4 font-normal whitespace-no-wrap text-16"
                            },
                            [_vm._v("\n              Age:\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "relative flex-1 ml-auto max-w-50 md:max-w-64"
                            },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.age,
                                      expression: "age"
                                    }
                                  ],
                                  staticClass:
                                    "w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow appearance-none border-grey-20 text-16",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.age = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "under19" } },
                                    [_vm._v("18 and under")]
                                  ),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "19-25" } }, [
                                    _vm._v("19 - 25")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "26-35" } }, [
                                    _vm._v("26 - 35")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "36-45" } }, [
                                    _vm._v("36 - 45")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "46-64" } }, [
                                    _vm._v("46 - 64")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "over64" } }, [
                                    _vm._v("65+")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-grey-20"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-4 h-4 fill-current",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 20 20"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex items-center mt-2" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mr-4 font-normal whitespace-no-wrap text-16"
                            },
                            [_vm._v("\n              Gender:\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "relative flex-1 ml-auto max-w-50 md:max-w-64"
                            },
                            [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.gender,
                                      expression: "gender"
                                    }
                                  ],
                                  staticClass:
                                    "w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow appearance-none border-grey-20 text-16",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.gender = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "male" } }, [
                                    _vm._v("Male")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "female" } }, [
                                    _vm._v("Female")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "other" } }, [
                                    _vm._v("Other")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-grey-20"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "w-4 h-4 fill-current",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 20 20"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._m(0),
                        _vm._v(" "),
                        _vm._m(1)
                      ]
                    )
                  ]
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.askForDemographics
            ? [
                _c(
                  "div",
                  { staticClass: "w-full my-auto max-w-94" },
                  [
                    _c("svg-icon", {
                      staticClass: "mt-auto mb-2 party-hat",
                      attrs: { icon: "party" }
                    }),
                    _vm._v(" "),
                    _c("h2", { staticClass: "mb-2 font-medium text-28" }, [
                      _vm._v("You're all done!")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb-2 font-light leading-tight text-28" },
                      [_vm._v("Thanks for completing the poll.")]
                    ),
                    _vm._v(" "),
                    !_vm.complete
                      ? [
                          _vm._m(2),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              staticClass:
                                "flex items-end w-full mt-8 max-w-88",
                              attrs: { action: "/" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.finish($event)
                                }
                              }
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "flex-grow flex-shrink font-light text-14"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "visually-hidden" },
                                    [_vm._v("Enter your email")]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.email,
                                        expression: "email"
                                      }
                                    ],
                                    staticClass:
                                      "flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border border-grey-20 text-16",
                                    attrs: {
                                      placeholder: "me@example.com",
                                      type: "email"
                                    },
                                    domProps: { value: _vm.email },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.email = $event.target.value
                                      }
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "flex-grow-0 flex-shrink-0 h-10 px-6 font-serif antialiased italic font-normal text-white cursor-pointer text-14 bg-theme-highlight hover:bg-theme-dark",
                                  attrs: { type: "submit" }
                                },
                                [_vm._v("Sign Up")]
                              )
                            ]
                          )
                        ]
                      : _c(
                          "div",
                          {
                            staticClass:
                              "mt-4 font-serif italic font-normal text-18 text-theme-highlight"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.message) +
                                "\n        "
                            )
                          ]
                        )
                  ],
                  2
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "h-px mt-auto mb-0" })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative self-stretch md:order-1 order-0 md:w-c6" },
        [
          _c("img", {
            staticClass:
              "relative z-0 hidden object-cover object-center w-full h-full md:block",
            attrs: { src: _vm.image }
          }),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass:
                "absolute bottom-0 right-0 m-8 text-white border-white button bg-black-40 z-1",
              attrs: { id: "card-close-button", to: "/" }
            },
            [_vm._v("Close")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mt-4 font-light text-12 leading-medium" },
      [
        _c("p", [
          _c("em", [
            _vm._v(
              "By submitting this form you, acknowledge having read and understood our "
            ),
            _c(
              "a",
              {
                staticClass: "text-theme-highlight",
                attrs: { href: "/privacy-policy/", target: "_blank" }
              },
              [_vm._v("Privacy Policy")]
            ),
            _vm._v(".")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex justify-end w-full mt-2" }, [
      _c(
        "button",
        {
          staticClass:
            "h-10 px-6 mt-4 ml-auto font-serif antialiased italic font-normal bg-white border rounded-full cursor-pointer text-14 text-theme-highlight border-theme-highlight hover:bg-theme-highlight hover:text-white",
          attrs: { type: "submit" }
        },
        [_vm._v("Submit")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mt-4 font-light max-w-88 text-16 leading-medium" },
      [
        _c("p", [
          _vm._v(
            "If you want to keep up to date with the progress of this project then sign up for updates below."
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }