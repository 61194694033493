var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative mt-8 mb-4" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", [
      _c(
        "input",
        _vm._b(
          {
            ref: "slider",
            staticClass: "slider",
            class: {
              mousedown: _vm.grab
            },
            attrs: { disabled: _vm.locked, type: "range" },
            domProps: { value: _vm.proxyValue },
            on: {
              mousedown: function($event) {
                _vm.grab = true
              },
              mouseup: function($event) {
                _vm.grab = false
              },
              change: _vm.update,
              input: _vm.update
            }
          },
          "input",
          _vm.$attrs,
          false
        )
      )
    ]),
    _vm._v(" "),
    _c(
      "input",
      _vm._g(
        {
          staticClass: "invisible hidden",
          attrs: { type: "text" },
          on: {
            input: function($event) {
              return _vm.$emit("update", $event.target.value)
            }
          }
        },
        _vm.$listeners
      )
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex items-center justify-between w-full mb-2" },
      [
        _c(
          "div",
          {
            staticClass:
              "flex items-center justify-start flex-grow-0 flex-shrink"
          },
          [
            _c("span", { staticClass: "relative inline-block w-1 h-6" }, [
              _c("span", { staticClass: "absolute left-0" }, [
                _vm._v("Disagree")
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex items-center justify-center flex-grow flex-shrink mx-auto"
          },
          [
            _c("span", { staticClass: "relative inline-block w-1 h-6" }, [
              _c(
                "span",
                {
                  staticClass: "absolute left-50",
                  staticStyle: { transform: "translateX(-50%)" }
                },
                [_vm._v("Neutral")]
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex items-center justify-end flex-grow-0 flex-shrink"
          },
          [
            _c("span", { staticClass: "relative inline-block w-1 h-6" }, [
              _c("span", { staticClass: "absolute right-0" }, [_vm._v("Agree")])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }