var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-slide-title",
        { staticClass: "mt-8 mb-3", attrs: { tag: "h2" } },
        [
          _c("transition", { attrs: { name: "fade-simple", mode: "out-in" } }, [
            _vm.showAnswers
              ? _c("div", { key: "if-show-answers" }, [
                  _vm._v("\n        Great!\n        "),
                  _c("span", { staticClass: "text-theme-highlight" }, [
                    _vm._v(_vm._s(_vm.matchingPercent))
                  ]),
                  _vm._v(" of your community agree with you!\n      ")
                ])
              : _c("div", { key: "if-not-show-answers" }, [
                  _vm._v(_vm._s(_vm.question.text))
                ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex flex-wrap -m-1 md:-m-2" },
        _vm._l(_vm.options, function(ref, i) {
          var bgColor = ref.bgColor
          var image = ref.image
          var id = ref.id
          var text = ref.text
          return _c(
            "div",
            {
              key: "choice-" + i + "-" + id,
              staticClass: "relative w-full p-1 md:p-2",
              class: {
                "sm:w-c6": [1, 2, 4].includes(_vm.options.length),
                "sm:w-c4": [3, 5, 6].includes(_vm.options.length)
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative m-0 overflow-hidden border-2 rounded-lg ar-16x9",
                  class: {
                    "opacity-100 border-theme-highlight":
                      _vm.selected.indexOf(id) > -1,
                    "border-transparent": _vm.selected.indexOf(id) === -1,
                    "opacity-60":
                      _vm.selected.length && _vm.selected.indexOf(id) === -1
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "absolute inset-0 z-0",
                      style: {
                        "background-color": bgColor
                      }
                    },
                    [
                      image
                        ? _c("img", {
                            staticClass:
                              "object-cover object-center w-full h-full",
                            attrs: { src: image.url, alt: image.title }
                          })
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "absolute inset-0 z-1",
                    class: {
                      "text-gradient-light": !_vm.showAnswers,
                      "text-gradient-dark": _vm.showAnswers
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "absolute top-0 bottom-0 right-0 w-6 transition-opacity z-1",
                      class: {
                        "opacity-0": !_vm.showAnswers
                      }
                    },
                    [
                      _c("div", {
                        staticClass:
                          "absolute left-0 right-0 h-px ml-4 bg-white top-25 z-2"
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "absolute left-0 right-0 h-px ml-2 bg-white top-50 z-2"
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "absolute left-0 right-0 h-px ml-4 bg-white top-75 z-2"
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "absolute bottom-0 left-0 right-0 bar bg-theme-highlight z-2",
                        style: {
                          height: _vm.calcPercent(id)
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    _vm.selectComponent,
                    {
                      tag: "component",
                      staticClass: "toggle-button z-3",
                      attrs: {
                        "aria-label": "Toggle " + text,
                        "aria-pressed": _vm.selected.indexOf(id) > -1
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggle(id)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "absolute inset-0 flex flex-col items-start justify-end p-4 z-1"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "self-end mt-0 mb-auto font-light text-white transition-opacity transition-delay percent-bar text-30",
                              class: {
                                "opacity-0": !_vm.showAnswers
                              }
                            },
                            [_vm._v(_vm._s(_vm.calcPercent(id)))]
                          ),
                          _vm._v(" "),
                          _vm.selected.indexOf(id) > -1
                            ? _c(
                                "div",
                                { staticClass: "relative mb-2" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "relative text-white z-1",
                                    attrs: { icon: "circle-tick", size: "6" }
                                  }),
                                  _vm._v(" "),
                                  _c("svg-icon", {
                                    staticClass:
                                      "absolute top-0 left-0 z-0 text-theme-highlight",
                                    attrs: { icon: "circle", size: "6" }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pr-6 leading-tight text-left label text-16 md:text-18"
                            },
                            [_vm._v(_vm._s(text))]
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }