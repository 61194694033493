var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag ? _vm.tag : "div",
    {
      tag: "component",
      staticClass:
        "font-medium leading-tight text-20 sm:text-24 lg:text-28 lg:max-w-md text-fg"
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }