var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "items-start mt-8 sm:flex" }, [
      _c("div", { staticClass: "lg:mt-8 sm:w-c6 md:w-c5" }, [
        _c(
          "div",
          { staticClass: "w-full" },
          [
            _c(
              "base-slide-title",
              { staticClass: "min-h-24" },
              [
                _c(
                  "transition",
                  { attrs: { name: "fade-simple", mode: "out-in" } },
                  [
                    _vm.showAnswers && _vm.matchingPercent
                      ? _c("div", { key: "showanswers-true" }, [
                          _vm._v("\n              Great!\n              "),
                          _c("span", { staticClass: "text-theme-highlight" }, [
                            _vm._v(_vm._s(_vm.matchingPercent))
                          ]),
                          _vm._v(
                            " of your community agree with you!\n            "
                          )
                        ])
                      : _vm.showAnswers
                      ? _c("div", { key: "showanswers-true-noanswer" }, [
                          _vm._v("Thanks for answering!")
                        ])
                      : _c("div", { key: "showanswers-false" }, [
                          _vm._v(_vm._s(_vm.question.text))
                        ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("AgreeSlider", {
              attrs: { locked: _vm.showAnswers || _vm.locked, min: 0, max: 10 },
              model: {
                value: _vm.slider,
                callback: function($$v) {
                  _vm.slider = $$v
                },
                expression: "slider"
              }
            }),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade-up-delay" } }, [
              _vm.showAnswers && _vm.averageVote >= 0
                ? _c("div", { staticClass: "w-full h-8" }, [
                    _c(
                      "div",
                      {
                        staticClass: "average-vote-marker",
                        style: {
                          marginLeft:
                            "calc( " +
                            _vm.averageVote * 10 +
                            "% - " +
                            (_vm.averageVote - 5) * 3 +
                            "px )"
                        }
                      },
                      [
                        _c("svg-icon", {
                          staticClass:
                            "absolute top-0 left-0 block -ml-2 fill-current text-theme-highlight",
                          attrs: { icon: "indicator-arrow", size: "4" }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "average-vote-text",
                            class: {
                              "text-center":
                                _vm.averageVote > 1 && _vm.averageVote < 9,
                              "text-left left-0": _vm.averageVote <= 1,
                              "text-right right-0": _vm.averageVote >= 9
                            }
                          },
                          [_vm._v("Average Vote")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-10 sm:mt-0 sm:w-c6 md:w-c7 sm:pl-8" }, [
        _vm.question.image
          ? _c("div", [
              _c("img", {
                staticClass: "block w-full h-auto",
                attrs: {
                  src: _vm.question.image.url,
                  alt: _vm.question.image.title
                }
              })
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-right lg:mt-8" },
      [
        _c(
          "SaveButton",
          {
            attrs: {
              loading: _vm.$store.state.question.loading,
              complete: _vm.showAnswers,
              "next-url": _vm.nextUrl
            },
            on: { submit: _vm.submit }
          },
          [_vm.status ? [_vm._v(_vm._s(_vm.status))] : _vm._e()],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }