var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed inset-0 overflow-auto scrolling-touch z-a1 bg-black-80",
      attrs: { id: "focus-trap" }
    },
    [
      _c(
        "div",
        {
          staticClass: "py-20 global-container-xl global-padding min-h-screenh"
        },
        [
          _c(
            "div",
            {
              staticClass: "relative w-full max-w-6xl p-6 m-auto md:p-8 card",
              attrs: { id: "card-container" }
            },
            [
              _vm.loading
                ? _c(
                    "div",
                    {
                      staticClass:
                        "absolute inset-0 flex items-center justify-center p-8 text-black bg-white-80 z-1"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("svg-icon", {
                            staticClass: "text-black",
                            attrs: { icon: "tail-spin", size: "8" }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "font-bold uppercase text-12 tracking-serif"
                            },
                            [_vm._v("Loading")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "portal-target",
                { staticClass: "mb-4 md:mb-12", attrs: { name: "navbar" } },
                [
                  _c(
                    "div",
                    { staticClass: "h-4 mb-4" },
                    [
                      _vm.nextUrl
                        ? _c(
                            "router-link",
                            {
                              staticClass:
                                "inline-block antialiased font-bold text-16 text-lightgrey-d",
                              attrs: { to: _vm.nextUrl }
                            },
                            [_vm._v("Skip")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "font-light text-14 opacity-85 text-theme-fg"
                    },
                    [
                      _vm._v(
                        "Question " +
                          _vm._s(_vm.$route.params.step) +
                          " / " +
                          _vm._s(_vm.questions.length)
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex pt-1 pb-2 overflow-hidden rounded-lg"
                    },
                    _vm._l(_vm.questions, function(question) {
                      return _c(
                        "router-link",
                        {
                          key: "question-" + question.i,
                          staticClass:
                            "relative flex-grow flex-shrink inline-block px-2 py-1 m-0 mx-px leading-none text-white rounded-lg text-12 hover:opacity-100 tab-link",
                          class: {
                            "bg-theme-highlight opacity-70":
                              parseInt(_vm.$route.params.step) >
                              parseInt(question.i),
                            "bg-theme-highlight":
                              parseInt(_vm.$route.params.step) ===
                              parseInt(question.i),
                            "bg-black-20 hover:bg-black-60":
                              parseInt(_vm.$route.params.step) <
                              parseInt(question.i)
                          },
                          attrs: {
                            "aria-label": "Go to " + question.i,
                            to: "/" + _vm.slug + "/" + question.i + "/"
                          }
                        },
                        [_c("div", { staticClass: "absolute inset-0 -my-2" })]
                      )
                    }),
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "relative z-0",
                  class: {
                    //  'opacity-0 pointer-events-none': loading
                  }
                },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "absolute top-0 right-0 m-4 text-black z-5",
                  attrs: { to: "/", "aria-label": "Close" }
                },
                [_c("svg-icon", { attrs: { icon: "close", size: "4" } })],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("store-error")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }