<template>
  <component
    :is="tag ? tag : 'div'"
    class="font-medium leading-tight text-20 sm:text-24 lg:text-28 lg:max-w-md text-fg"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: ['tag'],
}
</script>

<style>
</style>
