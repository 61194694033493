<template>
  <div>
    <div class="items-stretch mt-8 sm:flex">
      <div class="flex flex-col self-stretch sm:w-c5">
        <div class="flex-grow-0 w-full max-w-70">
          <base-slide-title tag="h2" class>{{ question.text }}</base-slide-title>
        </div>
        <div class="mt-8 mb-0" v-if="question.image">
          <img :src="question.image.url" :alt="question.image.title" class="block w-full h-auto" />
        </div>
      </div>
      <div class="flex flex-col mt-8 sm:w-c7 sm:mt-0 sm:pl-8">


        <transition name="fade-simple" mode="out-in" >
          <p v-if="showAnswers" class="flex-grow-0 flex-shrink-0 mb-4 font-light text-16 max-w-60 text-grey leading-medium">Thanks for letting us know!</p>
          <label
            v-else
              class="flex-grow-0 flex-shrink-0 mb-4 font-light text-16 max-w-60 text-grey leading-medium"
              :for="`textarea-${question.qid}-${question.pollId}`"
            >Enter your answer below:</label>
        </transition>

        <transition name="fade-simple" mode="out-in">
          <div v-if="showAnswers" class="textarea-fixed">{{ submittedAnswer }}</div>
          <div v-else class="flex flex-col flex-grow" key="textarea-form">
            <textarea
              class="flex-grow min-h-40 textarea"
              v-model="answer"
              :id="`textarea-${question.qid}-${question.pollId}`"
            ></textarea>
          </div>
        </transition>

      </div>
    </div>
    <div class="mt-8 text-right">
      <SaveButton
        @submit="submit"
        :loading="$store.state.question.loading"
        :complete="showAnswers"
        :next-url="nextUrl"
      >
        <template v-if="status">{{ status }}</template>
      </SaveButton>
    </div>
  </div>
</template>

<script>

import SaveButton from '@/vue/components/inputs/SaveButton.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    question: {
      type: Object,
    },
    nextUrl: {
      type: [String, Boolean],
      required: false,
    },
  },
  components: {
    SaveButton,
  },

  computed: {
    ...mapGetters('question', [
      'hasAnswered',
    ]),
    showAnswers() {
      return this.hasAnswered || this.complete
    },
    submittedAnswer() {
      const { answers } = this.question
      if (answers) {
        const { user } = answers
        return user
      }

      return false
    }
  },

  data() {
    return {
      answer: '',
      status: '',
      locked: false,
      complete: false,
    }
  },
  watch: {
      answer(a) {
        if ( a ) {
          this.status = ''
        }
      }
  },
  methods: {

    checkCanSubmit() {
      if ( !this.answer ) {
        this.status = "please give an answer above"

        return false
      }

      return true
    },
    async submit() {

      if ( !this.checkCanSubmit() ) return

      this.locked = true

      const success = await this.$store.dispatch('question/submit', {
        answer: this.answer,
        qid: this.question.qid,
      })

      if ( success ) {
        this.complete = true
      } else {
        this.locked = false
      }

    }
  },
}
</script>

<style scoped lang="scss">
.textarea {
  @apply p-4;
  @apply border-lightgrey-d;
  @apply border;
  @apply rounded-lg;
  @apply w-full;
  @apply flex-grow;
  @apply text-18;
  @apply font-sans;

  &:focus {
    outline: 0;
    @apply border-theme-highlight;
    box-shadow: 0 0 2px 0 var(--theme-highlight);
  }
}

.textarea-fixed {
  @apply p-4;
  @apply bg-lightgrey-e-40;
  @apply text-black-70;
  @apply italic;
  @apply border;
  @apply border-transparent;
  @apply rounded-lg;
  @apply w-full;
  @apply flex-grow;
  @apply text-18;
  @apply font-sans;
}
</style>
