<!--

Note that this isn't really a "default" template
by normal means, this is a default for the POLLS
which means that it has an overlay,

-->
<template>
  <div id="focus-trap" class="fixed inset-0 overflow-auto scrolling-touch z-a1 bg-black-80">
    <div class="py-20 global-container-xl global-padding min-h-screenh">
      <!-- Beginning of card -->
      <div id="card-container" class="relative w-full max-w-6xl p-6 m-auto md:p-8 card">
        <div
          v-if="loading"
          class="absolute inset-0 flex items-center justify-center p-8 text-black bg-white-80 z-1"
        >
          <div class="text-center">
            <svg-icon icon="tail-spin" class="text-black" size="8" />
            <div class="font-bold uppercase text-12 tracking-serif">Loading</div>
          </div>
        </div>

        <!-- NAV BAR -->
        <portal-target name="navbar" class="mb-4 md:mb-12">
          <div class="h-4 mb-4">
            <router-link
              v-if="nextUrl"
              class="inline-block antialiased font-bold text-16 text-lightgrey-d"
              :to="nextUrl"
            >Skip</router-link>
          </div>

          <!--
              <ProgressBar class="my-2" :question="step" :total="totalQuestions" :progress="progress" />
          -->

          <div
            class="font-light text-14 opacity-85 text-theme-fg"
          >Question {{ $route.params.step }} / {{ questions.length }}</div>

          <div class="flex pt-1 pb-2 overflow-hidden rounded-lg">
            <router-link
              v-for="question in questions"
              class="relative flex-grow flex-shrink inline-block px-2 py-1 m-0 mx-px leading-none text-white rounded-lg text-12 hover:opacity-100 tab-link"
              :class="{
                  'bg-theme-highlight opacity-70': parseInt($route.params.step) > parseInt(question.i),
                  'bg-theme-highlight': parseInt($route.params.step) === parseInt(question.i),
                  'bg-black-20 hover:bg-black-60': parseInt($route.params.step) < parseInt(question.i),
                }"
              :aria-label="`Go to ${ question.i }`"
              :to="`/${slug}/${question.i}/`"
              :key="`question-${question.i}`"
            >
              <div class="absolute inset-0 -my-2"></div>
              <!-- <span class="inline-block mr-2 align-middle opacity-50 text-10">{{ question.type }}</span> -->
              <!--  -->
            </router-link>
          </div>
        </portal-target>

        <!-- MAIN DEFAULT SLOT -->
        <!-- This is basically where the vue route goes...hidden if 'loading' -->
        <div
          :class="{
            //  'opacity-0 pointer-events-none': loading
            }"
          class="relative z-0"
        >
          <slot />
        </div>

        <router-link class="absolute top-0 right-0 m-4 text-black z-5" to="/" aria-label="Close">
          <svg-icon icon="close" size="4" />
        </router-link>
      </div>

      <!-- <portal-target
          class="relative w-full max-w-4xl p-4 mx-auto mt-8 z-2 card"
          name="debug"
          multiple
      />-->

      <!-- End of card -->
    </div>

    <store-error />
  </div>
</template>

<script>
import StoreError from "@/vue/components/StoreError.vue";
import { mapState } from 'vuex'
export default {
  components: {
    StoreError,
  },

  computed: {
    user() {
      return this.$store.state.user
    },
    ...mapState('polls', [
      'title',
      'slug',
      'id',
      'questions',
    ]),

    ...mapState({
      loading: state => state.polls.loading
    }),

    nextStep() {
      const i = parseInt(this.$route.params.step) + 1;
      if (i - 1 === this.questions.length) {
        return false
      }

      return i
    },

    // Not gonna be needed but w/e it's useful for debuggin
    prevUrl() {
      if (parseInt(this.$route.params.step) === 1) return false;
      return `/${this.$route.params.poll}/${parseInt(this.$route.params.step) - 1}`
    },

    nextUrl() {
      if (!this.nextStep) return false;
      return `/${this.$route.params.poll}/${this.nextStep}`
    }
  },


}
</script>

<style>
.fix-body {
  overflow: hidden;
  height: 100%;
}

#focus-trap {
  opacity: 0;
  transition: opacity .3s ease;
}

.fix-body #focus-trap {
  opacity: 1;
  transition: opacity .3s ease .3s;
}
</style>

<style scoped>
.router-link {
  @apply border-2;
  @apply border-transparent;
  @apply bg-black-10;
  @apply px-2;
  @apply m-1;
}
.router-link.router-link-active {
  @apply border-2;
  @apply border-skyblue;
}
.router-link.router-link-exact-active {
  @apply font-bold;
  @apply text-skyblue;
}

.tab-link {
  transition: opacity 0.3s ease, background-color 0.3s ease;
}
</style>
